import { ReactNode, useState } from 'react';
import EnhancedTableOnDate from '../Tables/common/EnhancedTableOnDate';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeliveryListItemProps } from '../../interfaces/DeliveryListProps.ts';
import { getScheduledAndInProgressDeliveryColumns } from './getScheduledAndInProgressDeliveryColumns.tsx';
import { getInterruptedAndFinishedDeliveryColumns } from './getInterruptedAndFinishedDeliveryColumns.tsx';
import { DeliveryListTabNames } from '@shopopop/react-hooks';
import { useDeliveriesData } from './mock/useDeliveriesData';

interface DeliveriesListProps {
  deliveries: DeliveryListItemProps[];
  currentTab: DeliveryListTabNames;
}

function DeliveriesList({ deliveries, currentTab }: DeliveriesListProps): ReactNode {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const { data, loading } = useDeliveriesData({ deliveries, currentTab, currentPage, pageSize });

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = (() => {
    switch (currentTab) {
    case DeliveryListTabNames.Schedule:
    case DeliveryListTabNames.InProgress:
      return getScheduledAndInProgressDeliveryColumns({ navigate, t, currentTab });
    case DeliveryListTabNames.Interrupted:
    case DeliveryListTabNames.DeliveredAndFinished:
      return getInterruptedAndFinishedDeliveryColumns({ navigate, t });
    default:
      return [];
    }
  })();

  return (
    <EnhancedTableOnDate
      data={data}
      loading={loading}
      columns={columns}
      onChange={handleTableChange}
      pagination={{
        current: currentPage,
        pageSize,
        pageSizeOptions: [10, 15, 20],
      }}
    />
  );
}

export default DeliveriesList;
